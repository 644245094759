$(document).ready(function () {
  // Run on opening of inventory item modal window
  $('.showInventoryItemOrContainer').on('shown.bs.modal', function () {
    //////////////////////////////////////////////////////////
    ///////// PURCHASE ITEM PREVIEW
    // -- Will go here.
    //////////////////////////////////////////////////////////
    ///////// TRANSFER ITEM FILTER
    // var filter_options = "#transfer_item_filter_inventories_player, #transfer_item_filter_inventories_shops, #transfer_item_filter_inventories_caches, #transfer_item_filter_inventories_encounters, #transfer_item_filter_inventories_owned"
    var formoptions = ["transfer_item", "copy_container", "transfer_container"]
    var checkboxoptions = ["player", "shops", "caches", "encounters", "owned"]
    var filter_options = ""

    // Compile list of possible divs
    formoptions.forEach(function (formElement) {
      checkboxoptions.forEach(function (checkElement) {
        filter_options += formElement + "_filter_inventories_" + checkElement + ", ";
      });
    });
    var dropdown_ids = "#transfer_item_transfer_inventory, #copy_container_copy_inventory, #transfer_container_transfer_inventory"

    // Change the transfer inventory dropdown on successful ajax
    $('#filter_inventories_selection, #filter_inventories_selection_container').on('ajax:success', filter_options.to_s, function (event) {
      var detail = event.detail;
      var data = detail[0], status = detail[1], xhr = detail[2];

      var inventory_select = $(dropdown_ids);
      inventory_select.empty();

      $.each(data, function (index, value) {
        var opt = $('<option/>');
        opt.attr('value', value[1]);
        opt.text(value[0]);
        opt.appendTo(inventory_select);
      });
    });
    ///////////////////////////////////////////////////////
  });
});
