import trumbowyg from "trumbowyg"

import trumbowIconsPath from "../images/icons.svg"

// function trumbow_editor_loader() {
window.trumbow_editor_loader = function() {
  // JavaScript to fill in post content on from submission
  var form = document.querySelector('.trumbowyg_form');
  if(form !== null) {
    form.onsubmit = function() {
      var postContentInput_one = document.querySelector('#editorContentOne');
      if(postContentInput_one !== null) { postContentInput_one.value = document.querySelector("#trumbowyg.trumbowyg-editor").innerHTML; }

      var postContentInput_two = document.querySelector('#editorContentTwo');
      if(postContentInput_two !== null) { postContentInput_two.value = document.querySelector("#trumbowygTwo.trumbowyg-editor").innerHTML; }

      var postContentInput_three = document.querySelector('#editorContentThree');
      if(postContentInput_three !== null) { postContentInput_three.value = document.querySelector("#trumbowygThree.trumbowyg-editor").innerHTML; }

      var postContentInput_four = document.querySelector('#editorContentFour');
      if(postContentInput_four !== null) { postContentInput_four.value = document.querySelector("#trumbowygFour.trumbowyg-editor").innerHTML; }

      var postContentInput_five = document.querySelector('#editorContentFive');
      if(postContentInput_five !== null) { postContentInput_five.value = document.querySelector("#trumbowygFive.trumbowyg-editor").innerHTML; }
    };
  }
}

// Modal editor with different options. Cannot have a fullscreen button.
window.modal_trumbow_loader = function() {
  $('.trumbyContainer').trumbowyg({
    svgPath: trumbowIconsPath,
    btnsDef: {
       customFormatting: {
         dropdown: ['p', 'h1', 'h2', 'h3', 'h4', 'h5'],
         ico: 'p'
       },
       align: {
         dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
         ico: 'justifyLeft'
       }
    },
    btns: [
      // ['viewHTML'],
      ['undo', 'redo'],
      ['fontfamily', 'fontsize', 'customFormatting', 'blockquote'],
      ['strong', 'em', 'del', 'underline'],
      ['foreColor', 'backColor'],
      ['link'],
      ['table'],
      ['insertImage', 'base64', 'emoji'],
      ['align'],
      ['indent', 'outdent'],
      ['unorderedList', 'orderedList'],
      ['superscript', 'subscript'],
      ['horizontalRule'],
      ['removeformat'],
      ['fullscreen']
      // REMEMBER!!! Cannot copy/paste. No fullscreen button.
    ],
    tagsToRemove: ['script', 'iframe'],
    imageWidthModalEdit: true,
    urlProtocol: true,
    semantic: {
      'del': 's',
    }
  });
}


// Load em up
$(document).ready(function() {
  trumbow_editor_loader();

  // Normal loader
  $('.trumbyContainer').trumbowyg({
    svgPath: trumbowIconsPath,
    btnsDef: {
       customFormatting: {
         dropdown: ['p', 'h1', 'h2', 'h3', 'h4', 'h5'],
         ico: 'p'
       },
       align: {
         dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
         ico: 'justifyLeft'
       }
    },
    btns: [
      // ['viewHTML'],
      ['undo', 'redo'],
      ['fontfamily', 'fontsize', 'customFormatting', 'blockquote'],
      ['strong', 'em', 'del', 'underline'],
      ['foreColor', 'backColor'],
      ['link'],
      ['table'],
      ['insertImage', 'base64', 'emoji'],
      ['align'],
      ['indent', 'outdent'],
      ['unorderedList', 'orderedList'],
      ['superscript', 'subscript'],
      ['horizontalRule'],
      ['removeformat'],
      ['fullscreen']
    ],
    tagsToRemove: ['script', 'iframe'],
    imageWidthModalEdit: true,
    urlProtocol: true,
    semantic: {
        'del': 's',
    }
  });

  $('.modal.editorModal').on('shown.bs.modal', function (e) {
    modal_trumbow_loader();
    trumbow_editor_loader();

    // HIDE the two buttons utilizing modals on modal editors - the modals do not stack and cause bugs.
    // $('.trumbowyg-fullscreen-button').hide();
    // $('.trumbowyg-link-button').parent('div').hide();

    // On MODAL editor, when full screen is open, add a class to the parent modal to mark it.
    // On MARKED modal hidden.bs.modal, STOP. Show modal. Remove class. (That way it can be closed next time.)
    $('.editorModal .trumbyContainer').trumbowyg().on('tbwopenfullscreen', function() {
      console.log('Open Full Screen');
      $(this).closest('.editorModal').addClass('fullscreenModalEditorON');
    });

    $('.editorModal .trumbyContainer').trumbowyg().on('tbwclosefullscreen', function() {
      console.log('Close Full Screen');
    });
    ////////////////////////////////////////
  });

  // On MODAL editor, when full screen is open, add a class to the parent modal to mark it.
  // On MARKED modal hidden.bs.modal, STOP. Show modal. Remove class. (That way it can be closed next time.)
  $('.editorModal .trumbyContainer').trumbowyg().on('tbwopenfullscreen', function() {
    console.log('Open Full Screen');
    $(this).closest('.editorModal').addClass('fullscreenModalEditorON');
  });

  $('.editorModal .trumbyContainer').trumbowyg().on('tbwclosefullscreen', function() {
    console.log('Close Full Screen');
  });

  $('.editorModal').on('hide.bs.modal', function (e) {
    console.log("Editor Modal is Hiding");
    if ($(this).hasClass('fullscreenModalEditorON')) {
      console.log("Stop Modal");
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      $(this).modal('show');
      $(this).removeClass('fullscreenModalEditorON');
    }
  });
  ////////////////////////////////////////

  $('#editCellPlot-tab').on('shown.bs.tab', function (e) {
    e.target // newly activated tab
    e.relatedTarget // previous active tab
    modal_trumbow_loader();
    trumbow_editor_loader();
  });

  // Tab button fix
  $('.trumbyContainer').on('keydown', function(e){
      if(e.keyCode == 9 && e.shiftKey) {
          document.execCommand ( 'styleWithCSS', true, null )
          document.execCommand ( 'outdent', true, null )
          e.preventDefault()
      } else if(e.keyCode == 9) {
          console.log("key", e.keyCode)
          document.execCommand ( 'styleWithCSS', true, null )
          document.execCommand ( 'indent', true, null )
          e.preventDefault()
      }
  });
});
