/* ===========================================================
 * trumbowyg.pasteimage.js v1.0
 * Basic base64 paste plugin for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Alexandre Demode (Alex-D)
 *          Twitter : @AlexandreDemode
 *          Website : alex-d.fr
 */

(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        plugins: {
            pasteImage: {
                init: function (trumbowyg) {
                    trumbowyg.pasteHandlers.push(function (pasteEvent) {
                        try {
                            var items = (pasteEvent.originalEvent || pasteEvent).clipboardData.items,
                                mustPreventDefault = false,
                                reader;

                            for (var i = items.length - 1; i >= 0; i -= 1) {
                                if (items[i].type.match(/^image\//)) {
                                  reader = new FileReader();

                                  //////////////////////////// Lithxe add
                                  // Check the file width and height before adding.
                                  reader.onload = (function(theFile) {
                                      var image = new Image();
                                      image.src = theFile.target.result;

                                      image.onload = function() {
                                        // access image size here
                                        // console.log(this.width);

                                        if(this.width > 500) {
                                          alert('Image is too large. Sorry! Files pasted in the editor have a limit of 500px on either side to avoid high load times.');
                                          return false;
                                        } else if(this.height > 500) {
                                          alert('Image is too large. Sorry! Files pasted in the editor have a limit of 500px on either side to avoid high load times.');
                                          return false;
                                        } else {
                                          // Send and post image
                                          trumbowyg.execCmd('insertImage', image.src, false, true);
                                          console.log("Image added successfully.");
                                          // next(file); // go back to component and send to the server
                                        }
                                      };
                                    });

                                    /* jshint -W083 */
                                    // Commented out and moved to the check above for size
                                    // reader.onloadend = function (event) {
                                        // trumbowyg.execCmd('insertImage', event.target.result, false, true);
                                    // };
                                    /* jshint +W083 */
                                    reader.readAsDataURL(items[i].getAsFile());

                                    mustPreventDefault = true;
                                }
                            }

                            if (mustPreventDefault) {
                                pasteEvent.stopPropagation();
                                pasteEvent.preventDefault();
                            }
                        } catch (c) {
                        }
                    });
                }
            }
        }
    });
})(jQuery);
